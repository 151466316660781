<template>
    <CModal
      title="COMPLETE CONFIRMATION"
      :show.sync="openModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <span>Please click confirm to complete</span>
      <template #footer>
        <CButton @click="submitComplete" color="success" class="branding-btn">CONFIRM</CButton>
        <CButton @click="openModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>
</template>
<script>
export default {
    data(){
      return {
        openModal: false,
        form_complete: {
          id: ''
        },
        amount: ''
      }
    },
    props: ['confirmationModal'],
    watch: {
      confirmationModal(newVal){
        console.log(newVal);
        this.form_complete.id = newVal.id;
        this.amount = newVal.total;
        this.openModal = true;
      }
    },
    methods: {
       submitComplete(){
        this.$store.dispatch('sales/submitCompleted', this.confirmationModal).then(response => {
          this.openModal = false;
        });
        
      },
    },
    created(){
    }
}
</script>