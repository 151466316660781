<template>
    <CModal
      title="Start Wash"
      :show.sync="openModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <multiselect 
        v-if="washboy"
        v-model="form_startwash.washboy_id" 
        :options="washboy | filterWashboy" 
        :multiple="true" 
        label="label" 
        track-by="label"
        class="mt-2"
      />
      <template #footer>
        <CButton @click="startWashSubmit" color="success" class="branding-btn">START</CButton>
        <CButton @click="openModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>
</template>
<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapGetters } from 'vuex';

export default {
    components: {
      Multiselect
    },
    data(){
      return {
          openModal: false,
          form_startwash: {
            washboy_id: '',
            id: '',
          },
          multiselectOptions: [
            { value: 'AL', label: 'Alabama' },
            { value: 'AK', label: 'Alaska' },
            { value: 'AS', label: 'American Samoa', $isDisabled: true },
          ]
      }
    },
    props: ['startModal'],
    filters: {
        filterWashboy: function (value) {
            if(value){
                var i;
                var washboy = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].name){
                        washboy.push({ 
                        value: value[i].id,
                        label: value[i].name
                        }); 
                    }
                }
                return washboy;
            }
        },
    },
    watch: {
      startModal(newVal){
        this.form_startwash.id = newVal.id;
        this.openModal = true;
        this.$store.dispatch('washboy/fetchWashboy');
      }
    },
    computed: {
      ...mapGetters({ 
        washboy: "washboy/washboy",
      }),
    },
    methods: {
      startWashSubmit(){
        this.$store.dispatch('sales/startWashSubmit', this.form_startwash).then(response => {
          this.openModal = false;
          this.form_startwash = {
            washboy_id : '', 
            id: ''
          };
        });
      },
    },
    created(){
    }
}
</script>
