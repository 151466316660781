<template>
    <CRow>
        <CCol v-show="!transaction_deleted_history" lg="4">
            <CCard>
            <CCardHeader style="position:relative;">
                <strong>WAITING LIST</strong>
                <div class="card-header-actions">
                <CButton v-if="$ability.can('read', 'LfrJngJ')" @click="addModalData = new Date()" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="8vOByTO" customClass="headerBtn" actionKey="create" :keyPage="$route.name"/>
                </div>
            </CCardHeader>
            <CCardBody>
                <div v-if="getWaitingData != ''">
                    <div class="waiting-list mb-3" v-for="(item, index) in getWaitingData" :key="item.id">
                        <div class="list-count color-white" style="width:5%;">
                            <span>{{index+1}}</span>
                        </div>
                        <div class="list-plate_no" style="width:45%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                            <small v-if="item.member_id != 0" style="display:block;">{{item.member.name}}</small>
                            <small v-else style="display:block;">{{item.name}}</small>
                            <small v-if="item.member_id != 0">{{item.property.vehicle.vehicle_name}} ({{item.plate_no}})</small>
                            <small v-else>{{item.vehicle.vehicle_name}} ({{item.plate_no}})</small>
                        </div>
                        <div class="list-action" style="width:50%; display:flex; flex-wrap: wrap;" align="right">
                            <div style="position:relative; flex: 1 0 21%;">
                                <CButton @click="getValueStart(item)" size="sm" color="primary" class="btn-pill"><CIcon name="cil-car-alt"/></CButton>
                                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="m1lU6hj" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                            </div>
                            <div style="position:relative; flex: 1 0 21%;">
                                <CButton @click="getValuePayment(item)" size="sm" color="warning" class="btn-pill"><CIcon name="cil-money"/></CButton>
                                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="YwiUrIU" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                            </div>
                            <div style="position:relative; flex: 1 0 21%;">
                                <CButton @click="getEditValue(item)" customClass="tableBtn" size="sm" color="info" class="btn-pill"><CIcon name="cil-pencil"/></CButton>
                                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="bRI2gW0" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                            </div>
                            <div style="position:relative; flex: 1 0 21%;">
                                <CButton @click="getValueDel(item)" customClass="tableBtn" size="sm" color="danger" class="btn-pill"><CIcon name="cil-trash"/></CButton>
                                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="uUlGMP6" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                            </div>   
                            <div style="flex: 1 0 21%;" class="text-right color-white mt-3">
                                <small>Waiting <waiting-timer :time="item.created_at"/></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h4 class="text-center">NO WAITING</h4>
                </div>
            </CCardBody>
            </CCard>
        </CCol>
        <CCol v-show="!transaction_deleted_history" lg="8">
            <CCard>
            <CCardHeader style="position:relative;">
                <strong>ONPROGRESS</strong>
                <div class="card-header-actions">
                    <CLink @click="transaction_deleted_history = true"><CIcon style="color:#fff;" name="cil-trash"/></CLink>
                    <RoleModal v-if="$store.state.permission.permission_settings" customClass="headerBtn" actionKey="read" elementKey="St9eYef" :keyPage="$route.name"/>
                </div>
            </CCardHeader>
            <CCardBody>
                <div v-if="getOnProgressData != ''">
                    <div
                        v-for="inprogress in getOnProgressData"
                        :key="inprogress.id"
                        class="inprogress-custom">
                            <div class="ribbon-wrapper">
                                <div v-if="inprogress.payment.payment_status == 'unpaid'" class="ribbon bg-danger">
                                    UNPAID
                                </div>
                                <div v-else class="ribbon bg-success">
                                    PAID
                                </div>
                            </div>
                        <CRow>
                            <CCol lg="6">
                                <span>Customer Name: {{inprogress.name}}</span><br>
                                <span>Plate No.: {{inprogress.plate_no}}</span><br>
                                <template v-if="inprogress.transaction_type == 'member'">
                                    <span v-if="inprogress.property">VEHICLE: {{inprogress.property.vehicle.vehicle_name}}</span><br>
                                </template>
                                <template v-else>
                                    <span v-if="inprogress.vehicle">VEHICLE: {{inprogress.vehicle.vehicle_name}}</span><br>
                                </template>
                                <span>Membership: {{inprogress.transaction_type}}</span>
                            </CCol>
                            <CCol lg="6">
                                <span>Washboy:
                                    <span v-for="labor in inprogress.labors" :key="labor.id">
                                        <span v-if="labor.washboy">{{labor.washboy.name}}</span>,
                                    </span>
                                    <CLink
                                    v-c-tooltip="'Edit Washboy'"
                                    @click="editWashboy(inprogress)"
                                    class="card-header-action btn-setting">
                                        <CIcon width="12" name="cil-pencil" class="text-primary hover-resize"/>
                                    </CLink>
                                    </span><br>
                                <span>Time Consumed: <b class="timer"><timer :time="inprogress.start_wash"/></b></span><br>
                                <div class="mt-2">
                                    <CButtonGroup>
                                        <div style="position:relative;">
                                            <CButton @click="getValuePayment(inprogress)" color="warning">Payment</CButton>
                                            <RoleModal v-if="$store.state.permission.permission_settings" elementKey="ygzQRve" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                                        </div>
                                        <div style="position:relative;">
                                            <CButton @click="getEditValue(inprogress)" color="info">Edit</CButton>
                                            <RoleModal v-if="$store.state.permission.permission_settings" elementKey="hq7mlel" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                                        </div>
                                        <div style="position:relative;">
                                            <CButton @click="getValueDel(inprogress)" color="danger">Delete</CButton>
                                            <RoleModal v-if="$store.state.permission.permission_settings" elementKey="8LcHLdQ" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                                        </div>
                                        <template v-if="inprogress.payment.payment_status == 'unpaid'">
                                        <div style="position:relative;">
                                            <CButton disabled="disabled" color="success">Complete</CButton>
                                            <RoleModal v-if="$store.state.permission.permission_settings" elementKey="eBB4hRb" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                                        </div>
                                        </template>
                                        <template v-else>
                                            <div style="position:relative;">
                                                <CButton @click="getComplete(inprogress)" color="success">Complete</CButton>
                                                <RoleModal v-if="$store.state.permission.permission_settings" elementKey="eBB4hRb" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                                            </div>
                                        </template>
                                    </CButtonGroup>
                                </div>
                            </CCol>
                        </CRow>
                    </div>
               </div>
               <div v-else>
                   <h4 class="text-center">NO TRANSACTION</h4>
               </div>
            </CCardBody>
            </CCard>
        </CCol>
        <CCol v-show="transaction_deleted_history" lg="12">
            <CCard>
                <CCardHeader>
                    <strong>DELETED TRANSACTION</strong>
                    <div class="card-header-actions">
                        <CLink @click="transaction_deleted_history = false"><CIcon style="color:#fff;" name="cil-arrow-circle-left"/></CLink>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <!-- <DeletedHistoryTable 
                    :items="$store.state.sales.deleted_transaction"
                    /> -->
                </CCardBody>
            </CCard>
        </CCol>
        <add-modal 
            :addModalData="addModalData"
        />
        <confirm-modal
        :confirmationModal="confirmationModal"
        />
        <delete-modal
        :deleteModal="deleteModal"
        />
        <payment-modal
        :paymentModal="paymentModal"
        />
        <startwash-modal
        :startModal="startModal"
        />
        <edit-modal
        :editModalData="editModalData"
        />
        <edit-washboy
        :editWashboyModal="editWashboyModal"
        />
    </CRow>
</template>
<script>
import Timer from '../plugins/Timer.vue';
import AddModal from './AddModalSales.vue';
import ConfirmModal from './ConfirmationModalSales.vue';
import DeleteModal from './DeleteModalSales.vue';
import PaymentModal from './PaymentModalSales.vue';
import StartwashModal from './StartwashModalSales.vue';
import EditModal from './EditModalSales.vue';
import WaitingTimer from '../plugins/TimerMinutes.vue';
import EditWashboy from './EditWashboy';
import { mapGetters } from 'vuex';
import RoleModal from "../../containers/RoleModal"
import DeletedHistoryTable from "./DeletedHistoryTable"

export default {
    data(){
        return {
            addModalData: '',
            startModal: '',
            deleteModal: '',
            paymentModal: '',
            confirmationModal: '',
            editModalData: '',
            editWashboyModal: '',
            transaction_deleted_history: false
        }
    },
    components: {
        Timer,
        WaitingTimer,
        AddModal,
        ConfirmModal,
        DeleteModal,
        PaymentModal,
        StartwashModal,
        EditModal,
        EditWashboy,
        RoleModal,
        DeletedHistoryTable
    },
    computed:{
        ...mapGetters({ 
            waiting: "sales/waiting",
        }),
        getWaitingData(){
            var i;
            var data = [];
            if(this.waiting){
                for (i = 0; i < this.waiting.length; i++) {
                if(this.waiting[i].status == 'waiting'){
                    data.push(this.waiting[i]);
                    }
                }
            }
            return data
        },
        getOnProgressData(){
            var i;
            var data = [];
            if(this.waiting){
                for (i = 0; i < this.waiting.length; i++) {
                    if(this.waiting[i].status == 'inprogress'){
                        data.push(this.waiting[i]);
                    }
                }
            }
            return data
        },
    },
    methods: {
        editWashboy(data){
            this.editWashboyModal = {
                trigger: new Date(),
                data: data
            };
        },
        getValueStart(data){
            this.startModal = {
                trigger: new Date(),
                id: data.id
            };
        },
        getValueDel(data){
            this.deleteModal = {
                trigger: new Date(),
                data: data
            };
        },
        getValuePayment(data){
            this.paymentModal = {
                trigger: new Date(),
                data: data
            };
        },
        getEditValue(data){
            this.editModalData = {
                trigger: new Date(),
                data: data
            };
        },
        getComplete(data){
            if(data.transaction_type == 'member'){
                this.confirmationModal = {
                    trigger: new Date(),
                    id: data.id,
                    total: data.payment.total,
                    property_id: data.property_id
                };
            }else{
                this.confirmationModal = {
                    trigger: new Date(),
                    id: data.id,
                    total: data.payment.total
                };
            }
            
        }
    },
    created(){
        this.$store.dispatch('sales/fetchWaiting');
        this.$store.dispatch('discount/fetchDiscount');
        this.$store.dispatch('sales/deletedTransaction');
        
    }
}
</script>