<template>
    <CModal
      :show.sync="dangerModal"
      title="Delete confirmation"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      Are you sure you want to delete <b>{{form_delete.plate_no}}</b>?
      <template #footer>
        <CButton @click="confirmDelete(form_delete.id)" color="success" class="branding-btn">YES</CButton>
        <CButton @click="dangerModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>
</template>
<script>
export default {
    data(){
      return {
        dangerModal: false,
        form_delete: '',
      }
    },
    props: ['deleteModal'],
    watch: {
      deleteModal(newVal){
        this.form_delete = newVal.data;
        this.dangerModal = true;
      }
    },
    methods: {
      confirmDelete(){
        this.$store.dispatch('sales/removeTransaction', this.form_delete.id).then(response => {
          this.dangerModal = false;
        });
      },
    },
    created(){
    }
}
</script>