<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        :table-filter="true"
        pagination
        items-per-page-select
        :tableFilter='{ placeholder: "Search", label: " "}'
        :sorterValue.sync='sortSetting'
      >
        
        <template #customer_name="{item}">
          <td>
            {{item.member.name}}
          </td>
        </template>
        <template #vehicle="{item}">
          <td>
            <span v-if="item.vehicle">{{item.vehicle.vehicle_name}}</span>
          </td>
        </template>
        <template #membership="{item}">
          <td>
            {{item.transaction_type}}
          </td>
        </template>
        <template #washboy="{item}">
          <td>
            <span v-for="labor in item.labors" :key="labor.id">
                {{labor.washboy.name}},
            </span>
          </td>
        </template>
        <template #deleted_by="{item}">
          <td>
            <span v-if="item.deleted_by">{{item.deleted_by.name}}</span>
          </td>
        </template>
        <template #deleted_at="{item}">
          <td>
            {{$root.momentFormatDateTime(item.deleted_at)}}
          </td>
        </template>
        
        <template #action="{item}">
            <td>
                <CButtonGroup v-if="$ability.can('update', '1BaLwRx')">
                    <div style="position:relative;">
                        <CButton size="sm" @click="getValue(item)" color="info"><CIcon name="cil-sync"/></CButton>
                        <RoleModal v-if="$store.state.permission.permission_settings" elementKey="1BaLwRx" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                    </div>
                </CButtonGroup>
            </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import RoleModal from "../../containers/RoleModal"
export default {
  name: 'Table',
  data(){
    return {
        sortSetting: {
            column: 'deleted_at',
            asc: false
        }
    }
  },
  components: {
    RoleModal
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['work_order', 'customer_name', 'vehicle', 'plate_no', 'membership', 'washboy', 'deleted_by', 'deleted_at', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getValue(data){
        console.log(data);
        if (confirm('Are you sure you want to restore W.O. #: ' + data.work_order +'?')) {
            this.$store.dispatch('sales/restoreTransaction', data.id);
        }
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete');
    },
  }
}
</script>
