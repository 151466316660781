<template>
  <div style="display:inline;">
      <span v-if="minutes == '00'">{{seconds}} secs</span>
      <span v-else-if="hours == '00'">{{minutes}} mins {{seconds}} secs</span>
      <span v-else>{{hours}} hr {{minutes}} mins {{seconds}} secs</span>
  </div>
</template>
<script>

export default {
  name: 'Datepicker',
  props: ['time'],
  data() {
    return {
        time_count: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        counter: 0,
        my_timer :0,
        array_timer: [],
    };
  },
  methods: {
    timer(seconds) {
        this.my_timer = setInterval(() => {
            this.seconds = this.timerFormat(parseInt(++seconds) % 60)
            this.minutes = this.timerFormat(parseInt(seconds / 60, 10) % 60)
            this.hours = this.timerFormat(parseInt(seconds / 3600, 10))
        }, 1000);
    },
    pauseTimer() {
        clearInterval(this.my_timer)
    },
    timerFormat(timer) {
        return timer > 9 ? timer : '0' + timer;
    },
  },
  created(){
    const date1 = new Date(this.time);
    const date2 = new Date();
    const diffInMs = Math.abs(date2 - date1);
    const value = diffInMs / 1000;
    this.timer(value);
  }
};
</script>
<style>
.count_up span{
    background:#E0681E;
    padding:3px;
    font-size:15px;
    border-radius:5px;
}
</style>