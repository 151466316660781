<template>
    <CModal
      title="Payment"
      :show.sync="openModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
    <CRow v-if="member">
      <CCol v-if="member.status == 'Pending'">
        <h4>Membership Fee: <b>₱300</b></h4>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <h4>Sub Total: <b>₱{{payment.sub_total}}</b></h4>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <h4>Discounted: <b>₱{{payment.discounted}}</b></h4>
      </CCol>
    </CRow>
    
    <CRow>
      <CCol >
        <h3 style="border-top:1px solid #636F83;">Total: <b>₱{{payment.total}}</b></h3>
      </CCol>
    </CRow>
    
      <div class="calculation mt-2">
              <template v-if="payment.payment_status == 'unpaid'">
              <h4>CHANGE: ₱{{payment.changed | changeFilter}}</h4>
              <CInput 
              
              label="Amount"
              v-model="payment.amount"
              >
             
               </CInput>
              
              </template>
              <template v-else>
              <span>CHANGE: ₱{{payment.changed}}</span><br>
              <span>AMOUNT: ₱{{payment.amount}}</span>
              </template>
            </div>
      <template #footer>
        <CButton v-if="payment.payment_status == 'unpaid'" @click="submitPayment" color="success" class="branding-btn">SUBMIT PAYMENT</CButton>
        <CButton v-else disabled="disabled" color="success" class="branding-btn">SUBMIT PAYMENT</CButton>
        <CButton @click="openModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    components: {
    },
    data(){
      return {
          openModal: false,
          payment: {
            changed: 0,
            amount: 0
          },
          member: ''
      }
    },
    props: ['paymentModal'],
    filters: {
        changeFilter(value){
          var data = 0;
          if(value >= 0){
            data = value;
          }else{
            data = 0;
          }
          return data;
        }
    },
    watch: {
      paymentModal(newVal){
        this.payment = newVal.data.payment;
        this.member = newVal.data.member;
        this.openModal = true;
        console.log(newVal);
      },

      amount(newVal){
        this.payment.changed = newVal - parseInt(this.payment.total);
      },
    },
    computed: {
      ...mapGetters({ 
       
      }),
      amount(){
        return this.payment.amount;
      },
    },
    methods: {
      submitPayment(){
        const params = {
            data: this.payment,
            id: this.payment.id,
        }

        if(this.payment.amount >= this.payment.total){
            this.$store.dispatch('sales/submitPayment', params).then(response => {
              this.openModal = false;
            });
        }else{
            this.$store.dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Please check amount!'
            }, {root: true});
        }
      },
    },
    created(){
    }
}
</script>
